.streetPageContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
}

.grid {
    margin: 5vh 0;
    width: 85%;
    height: fit-content;
    column-count: 3;
    column-gap: 10px;

    @media (max-width: 800px) {
        column-count: 2;
      }

    @media (max-width: 400px) {
        column-count: 1;
      }
}

.photoItem {
    display: inline-block;
    width: 100%;
    margin-bottom: 7px;
    
    img {
        cursor: pointer;
        display: block;
        width: 100%;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.02);
          }
      }
}
