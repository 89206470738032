@font-face {
    font-family: "Raleway";
    src: url("./font/Raleway-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Merriweather";
    src: url("./font/Merriweather-Regular.ttf") format("truetype");
}

.navigationBar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 250px;
    height: 100%;
}

.navLink {
    text-decoration: none;
    color: black;
    font-kerning: normal;
}

.title {
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-variant-caps: petite-caps;
    font-family: 'Raleway';
    font-size: 26px;
    font-weight: 600;
    cursor: pointer;
}

.categories {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 25px;
    justify-content: flex-end;
    padding-bottom: 60px;
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 700;
}

.category {
    cursor: pointer;
    display: flex;
    height: 30px;
    align-items: center;
    transition: padding-right 0.2s ease;
}

.category:hover {
    border-right: 5px solid;
    padding-right: 5px;
    transition: padding-right 0.2s ease;
}

.selected {
    border-right: 5px solid;
    padding-right: 5px;
}

.footer {
    height: 15vh;
    font-family: 'Merriweather';
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 25px;
}

.about {
    font-size: 16px;
    cursor: pointer;
}

.instagramIcon {
    cursor: pointer;
}