.filmmakingPageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.videoBlock {
    display: flex;
    flex-direction: column;
    width: 853px;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
}

.videoTitle {
    font-family: 'Merriweather';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    word-spacing: 2px;
}

.videoDescription {
    font-family: 'Merriweather';
    line-height: 20px;   
    line-height: 170%;
    text-align: left;
    font-size: 14px;
}

a {
    text-decoration: underline;
    color: #000;
}
